






































import axios from "axios";
import {
  defineComponent,
  PropType,
  onMounted,
  ref,
  useContext,
} from "@nuxtjs/composition-api";
import { useConfig } from "~/composables";
import Preload from "~/components/Preload.vue";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import SfImage from '~/modules/catalog/product/components/SfImage.vue';
export default defineComponent({
  name: "Blog",
  props: {
    category_id: {
      type: String,
      default: "",
    },
    category_name: {
      type: String,
      default: "",
    },
    blog_list: {
      type: Boolean,
      default: false,
    },
    search_query: {
      type: String,
      default: "",
    },
    posts_list: {
      type: Array,
      default: [],
    },
  },
  components: {
    Preload,
    VueSlickCarousel,
    SfImage,
  },
  setup(props) {
    const {
      localePath,
      localeRoute,
      app: { i18n },
    } = useContext();
    const { config } = useConfig();
    const baseMediaUrl = config.value.secure_base_media_url;
    const image_width = config.value.amblog_post_image_width;
    const image_height = config.value.amblog_post_image_height;
    const baseUrl = config.value.secure_base_url;
    const posts = ref([]);
    const postsData = ref([]);
    const isLoaded = ref(false);
    const blogList = ref(false);
    const searchQuery = ref(null);
    // Function to generate the full URL for a blog post's image
    const postImageUrl = (imageName: string) => {
      let firstLetter = imageName.substring(0, 1);
      let secondLetter = imageName.substring(1, 2);
      return `${baseMediaUrl}amasty/blog/${imageName}`;
    };

    const customPaging = (slideIndex: number) => {
      // Customize the paging indicators here
      // You can return any HTML content or a string for each slide
      return `<span>${slideIndex + 1}</span>`;
    };

    // Fetch blog posts from the API on component mount
    onMounted(async () => {
      try {
        if (props.search_query) {
          searchQuery.value = props.search_query;
          const { data } = await axios.get(
            `${baseUrl}rest/V1/amasty_blog/search/${searchQuery.value}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
              },
            }
          );
          postsData.value = data;
          isLoaded.value = true;
        } else {
          postsData.value = props.posts_list;
        }

        isLoaded.value = true;
        blogList.value = props.blog_list;
      } catch (error) {
        isLoaded.value = true;
        console.error(error);
      }
    });

    return {
      posts,
      baseMediaUrl,
      postImageUrl,
      isLoaded,
      postsData,
      blogList,
      customPaging,
      searchQuery,
      image_width,
      image_height,
    };
  },
  data() {
    return {
      responsive: [
        {
          "breakpoint": 1024,
          "settings": {
            "slidesPerRow": 1,
            "rows": 1,
          }
        },
      ]
    }
  },
});
