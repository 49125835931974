//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { colorsValues as SF_COLORS } from "@storefront-ui/shared/variables/colors";
import SfIcon from "@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue";
// import SfPrice from "@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue";
import SfRating from "@storefront-ui/vue/src/components/atoms/SfRating/SfRating.vue";
// import SfImage from "@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue";
import SfImage from '~/modules/catalog/product/components/SfImage.vue';
import SfCircleIcon from "@storefront-ui/vue/src/components/atoms/SfCircleIcon/SfCircleIcon.vue";
import SfBadge from "@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue";
import SfButton from "@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue";
import SfColorPicker from "@storefront-ui/vue/src/components/molecules/SfColorPicker/SfColorPicker.vue";
// import SfColor from "@storefront-ui/vue/src/components/atoms/SfColor/SfColor.vue";
import { SfColor } from '@storefront-ui/vue';
import SfSelect from "@storefront-ui/vue/src/components/molecules/SfSelect/SfSelect.vue";
import SfPrice from "./SFPrice.vue";
import { useCart } from "~/modules/checkout/composables/useCart";
import { ref, useContext, useRouter } from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import { useConfig } from "~/composables";
import IconOutWishlist from "~/components/General/IconOutWishlist.vue";
import IconInWishlist from "~/components/General/IconInWishlist.vue";
import imagePlaceholder from "~/assets/images/product_placeholder.webp";
import { convertToLocaleDateTime2 } from "~/helpers/moment";
import {
  getSwatchData as getProductSwatchData,
  getName as getProductName,
  getDescription,
  getProductSku,
} from '~/modules/catalog/product/getters/productGetters';
export default {
  name: "SfProductCard",
  components: {
    SfPrice,
    SfRating,
    SfIcon,
    SfImage,
    SfCircleIcon,
    SfBadge,
    SfButton,
    SfColorPicker,
    SfColor,
    SfSelect,
    IconOutWishlist,
    IconInWishlist,
  },
  props: {
    image: {
      type: [Array, Object, String],
      default: "",
    },
    image_webp: {
      type: String,
      default: null,
    },
    imageWidth: {
      type: [Number, String],
      default: null,
    },
    imageHeight: {
      type: [Number, String],
      default: null,
    },
    badgeLabel: {
      type: String,
      default: "",
    },
    badgeColor: {
      type: String,
      default: "",
    },
    colors: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    short_description: {
      type: String,
      default: "",
    },
    product_custom_label: {
      type: [Array, Object, String],
      default: "",
    },
    flash_sale_qty: {
      type: Number,
      default: 0,
    },
    qty_ordered: {
      type: Number,
      default: 0,
    },
    product_image_label: {
      type: [Array, Object, String],
      default: "",
    },
    product_frame: {
      type: String,
      default: "",
    },
    link: {
      type: [String, Object],
      default: null,
    },
    /**
     * Link element tag
     * @deprecated will be removed in 1.0.0 use slot to replace content
     */
    linkTag: {
      type: String,
      default: undefined,
    },
    scoreRating: {
      type: [Number, Boolean],
      default: false,
    },
    reviewsCount: {
      type: [Number, Boolean],
      default: false,
    },
    maxRating: {
      type: [Number, String],
      default: 5,
    },
    regularPrice: {
      type: [Number, String],
      default: null,
    },
    specialPrice: {
      type: [Number, String],
      default: null,
    },
    wishlistIcon: {
      type: [String, Array, Boolean],
      default: "heart",
    },
    isInWishlistIcon: {
      type: [String, Array],
      default: "heart_fill",
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      default: false,
    },
    isAddedToCart: {
      type: Boolean,
      deafult: false,
    },
    addToCartDisabled: {
      type: Boolean,
      default: false,
    },
    imageTag: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object,
      default: () => ({}),
    },
    stock_status: {
      type: String,
      default: "",
    },
    price_range: {
      type: Object,
      default: null,
    },
    product_price_type_show: {
      type: String,
      default: "",
    },
    flash_sale_product_price: {
      type: Number,
      default: 0,
    },
    flash_sale_product_sale_price: {
      type: Number,
      default: 0,
    },
    flash_sale_product_discount: {
      type: Number,
      default: 0,
    },
    flash_sale_from: {
      type: String,
      default: null,
    },
    flash_sale_to: {
      type: String,
      default: null,
    },
    minimum_price: {
      type: Number,
      default: 0,
    },
    maximum_price: {
      type: Number,
      default: 0,
    },
    productPrice: {
      type: Number,
      default: 0,
    },
    productSpecialPrice: {
      type: Number,
      default: 0,
    },
    productPriceDiscount: {
      type: Number,
      default: 0,
    },
    freeGift: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_currently_flash_sale: {
      type: Boolean,
      default: false,
    },
    configurableOptions: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    imagePromo: {
      type: Object,
      default: null,
    },
    indexPromo: {
      type: Object,
      default: null,
    },
    pricePromo: {
      type: Object,
      default: null,
    },
    skusPromo: {
      type: Object,
      default: null,
    },
    product: {
      type: Object,
      default: null,
    },
    placeholder: {
      type: String,
      default: imagePlaceholder,
    },
    showColorSwatch: {
      type: Boolean,
      default: false,
    },
    isFlashSale: {
      type: Boolean,
      default: false,
    },
    isInEvent: {
      type: Boolean,
      default: true,
    }
  },
  setup(props) {

    const { addItem } = useCart();
    const { app } = useContext();
    const router = useRouter();
    const { customFormValue, setCustomFormValue } = useUiState();
    const { toggleFreeGiftModal } = useUiState();
    const selected_option = ref([]);
    const productConfiguration = ref({});
    const imageProduct = ref("");
    const productOldPrice = ref(0);
    const { config } = useConfig();
    const isAddingToCart = ref(false);
    const isDisableAddToCart = ref(true);
    const baseUrl = config.value.secure_base_url;
    const selected = ref("");
    const checked = ref(0);
    const alertMessage = ref("");
    const showColor = props.showColorSwatch;
    const flashSale = props.isFlashSale;

    const isFlashSaleToday = () => {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;
      var yyyy = today.getFullYear();
      if (dd < 10) { dd = '0' + dd; } 
      if (mm < 10) { mm = '0' + mm; } 
      
      let end_today = yyyy + '-' + mm + '-' + dd + ' 23:59:59';
      end_today = new Date(end_today);
      const flash_sale_end = new Date(convertDateTime(props.flash_sale_to));
      if (flash_sale_end <= end_today || flashSale) {
        return true;
      }
      return false;
    }

    const changeConfigOption = (id, attribute_id, event) => {
      setCustomFormValue("free-gift", id, attribute_id, event);
      alertMessage.value = "";
      setTimeout(async () => {
        const _arr = ref([]);
        let ci = 0,
          cii = 0;
        const configProduct = ref({});
        props.configurableOptions.map((option, index) => {
          const key = "free-gift-" + id + "-" + option.attribute_id;
          const value =
            typeof customFormValue.value[key] !== "undefined"
              ? customFormValue.value[key].split("|")
              : "|".split("|");
          _arr.value[index] = {
            attributeid: option.attribute_id,
            attributevalue: value[0],
          };
          configProduct.value[index] = {
            id: option.attribute_id,
            value: value[0],
          };
          if (value != "") {
            ci++;
          }
          cii++;
        });
        productConfiguration.value["option"] = configProduct.value;
        productConfiguration.value["isPromoItems"] = true;
        if (ci === cii) {
          isDisableAddToCart.value = false;
        } else {
          isDisableAddToCart.value = true;
        }
        _arr.value.sort(function (a, b) {
          return a.attributeid - b.attributeid;
        });
        checked.value = 0;
        Object.keys(props.indexPromo).map((index) => {
          let i = 0,
            ii = 0;
          if (checked.value === 0) {
            Object.keys(props.indexPromo[index]).map((_index) => {
              const _value = props.indexPromo[index][_index];
              if (
                parseInt(_arr.value[i].attributeid) === parseInt(_index) &&
                parseInt(_arr.value[i].attributevalue) === parseInt(_value)
              ) {
                ii++;
              }
              i++;
            });
            if (ii > 0) {
              selected.value = index;
            }
            if (i === ii) {
              selected.value = index;
              checked.value++;
            }
          }
        });
        if (selected.value !== "") {
          productOldPrice.value =
            props.pricePromo[selected.value].finalPrice.amount;
          imageProduct.value =
            baseUrl +
            "media/catalog/product" +
            props.imagePromo[selected.value];
        }
      }, 500);
    };

    const onAddToCart = (event) => {
      event.preventDefault();
      alertMessage.value = "";
      isAddingToCart.value = true;
      setTimeout(async () => {
        isAddingToCart.value = false;
        const _product = props.product;
        if (checked.value > 0) {
          const _productConfiguration = productConfiguration.value;
          const _sku = props.skusPromo.child[selected.value];
          await addItem({
            product: _product,
            quantity: 1,
            productConfiguration: _productConfiguration,
            customQuery: { sku: _sku.sku, rule_id: _sku.rule_id },
          });
          props.configurableOptions.map((option, index) => {
            setCustomFormValue(
              "free-gift",
              props.product.id,
              option.attribute_id,
              ""
            );
          });
          toggleFreeGiftModal(false);
          await router.push(app.localeRoute({ name: "cart" }));
        } else {
          if (props.product.__typename === "SimpleProduct") {
            const _sku = props.skusPromo;
            productConfiguration.value["isPromoItems"] = true;
            const _productConfiguration = productConfiguration.value;
            await addItem({
              product: _product,
              quantity: 1,
              productConfiguration: _productConfiguration,
              customQuery: { rule_id: _sku.rule_id },
            });
            toggleFreeGiftModal(false);
          } else {
            alertMessage.value = "Please choose options";
          }
        }
      }, 1000);
    };

    const updateProductConfigurationImage = (productImg) => {
      //console.log(product, 'updateProductConfigurationImage');
      if (productImg) {
        imageProduct.value = productImg;
      }
      return;
    }

    String.prototype.replaceAt = function (index, replacement) {
      return this.substring(0, index) + replacement + this.substring(index + replacement.length);
    }

    const censorPrice = (flash_sale_product_sale_price, flash_sale_from, flash_sale_to, _isInEvent) => {
      let price = Math.round(flash_sale_product_sale_price).toLocaleString();
      let _return = flash_sale_product_sale_price.toLocaleString();
      if (price.length == 1) {
        _return = price.replaceAt(0, 'x');
      } else if (price.length == 2) {
        _return = price.replaceAt(1, 'x');
      } else if (price.length == 3) {
        _return = price.replaceAt(1, 'xx');
      } else if (price.length == 5) {
        _return = price.replaceAt(2, 'xxx');
      } else if (price.length == 6) {
        _return = price.replaceAt(3, 'xxx');
      } else if (price.length == 7) {
        _return = price.replaceAt(4, 'xxx');
      } else if (price.length == 9) {
        _return = price.replaceAt(6, 'xxx');
      }
      const now = new Date();
      const start = new Date(convertDateTime(flash_sale_from));
      const end = new Date(convertDateTime(flash_sale_to));
      if (now > start && now < end && _isInEvent) {
        _return = flash_sale_product_sale_price.toLocaleString();
      }
      return _return;
    }

    const isFlashSaleEventTime = (flash_sale_from, flash_sale_to, _isInEvent) => {
      const now = new Date();
      const start = new Date(convertDateTime(flash_sale_from));
      const end = new Date(convertDateTime(flash_sale_to));
      if (now > start && now < end && _isInEvent) {
        return true;
      }
      return false
    }

    const convertDateTime = (dateValue) => {
      return convertToLocaleDateTime2(dateValue, config.value.timezone);
    };

    const flash_sale_qty_balance = props.flash_sale_qty - props.qty_ordered;

    return {
      changeConfigOption,
      selected_option,
      imageProduct,
      productOldPrice,
      onAddToCart,
      isAddingToCart,
      isDisableAddToCart,
      customFormValue,
      alertMessage,
      getProductSwatchData,
      updateProductConfigurationImage,
      showColor,
      flashSale,
      censorPrice,
      isFlashSaleEventTime,
      flash_sale_qty_balance,
      isFlashSaleToday,
    };
  },
  data() {
    return {
      openColorPicker: false,
    };
  },
  computed: {

    isSFColors() {
      return SF_COLORS.includes(this.badgeColor.trim());
    },
    badgeColorClass() {
      return this.isSFColors ? `${this.badgeColor.trim()}` : "";
    },
    currentWishlistIcon() {
      return this.isInWishlist ? this.isInWishlistIcon : this.wishlistIcon;
    },
    showAddedToCartBadge() {
      return !this.isAddingToCart && this.isAddedToCart;
    },
    ariaLabel() {
      return this.isInWishlist ? "Remove from wishlist" : "Add to wishlist";
    },
    wishlistIconClasses() {
      const defaultClass = "sf-button--pure sf-product-card__wishlist-icon";
      return `${defaultClass} ${this.isInWishlist ? "on-wishlist" : ""}`;
    },
    showBadge() {
      return this.colors.length > 5;
    },
  },
  methods: {
    toggleIsInWishlist() {
      this.$emit("click:wishlist", !this.isInWishlist);
    },
    handleSelectedColor(colorIndex) {
      if (this.colors.length > 0) {
        this.colors.map((color, i) => {
          if (colorIndex === i) {
            this.$emit("click:colors", color);
            this.openColorPicker = false;
          }
        });
      }
    },
    toggleColorPicker() {
      this.openColorPicker = !this.openColorPicker;
    },
    stripHtml(str) {
      if (!str) {
        return;
      }
      return str.replace(/<[^>]*>/g, "");
    },
  },
};
