












import {
  defineComponent,
  ref,
  useFetch,
  onMounted,
} from "@nuxtjs/composition-api";
import type { PropType, Ref } from "@nuxtjs/composition-api";
import { useContent } from "~/composables";
import type { CmsBlock } from "~/modules/GraphQL/types";
import ContentBlock from "./ContentBlock.vue";
import Preload from "~/components/Preload.vue";

export default defineComponent({
  name: "ContentBlocks",
  components: {
    ContentBlock,
    Preload,
  },
  props: {
    identifiers: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  setup(props) {
    const { loadBlocks } = useContent();
    const blocks = ref<CmsBlock[]>([]);
    const isLoaded: Ref<boolean> = ref(false);

    onMounted(async () => {
      if (props.identifiers) {
        blocks.value = await loadBlocks({ identifiers: props.identifiers });
        isLoaded.value = true;
      }
    });

    return {
      blocks,
      isLoaded,
    };
  },
});
