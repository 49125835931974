var render = function (_h,_vm) {var _c=_vm._c;return _c('div',_vm._g(_vm._b({class:[_vm.data.class, _vm.data.staticClass, 'sf-price'],style:([_vm.data.style, _vm.data.staticStyle])},'div',_vm.data.attrs,false),_vm.listeners),[_vm._t("regular",function(){return [_c('span',{staticClass:"sf-price__regular",class:{ 'display-none': !_vm.props.regular || _vm.props.special }},[_vm._v("\n      "+_vm._s(_vm.props.regular)+"\n    ")])]},null,{ props: _vm.props }),_vm._v(" "),(_vm.props.discount)?_vm._t("discount",function(){return [(
        _vm.props.discount.maximum_price.final_price.value <
        _vm.props.discount.maximum_price.regular_price.value
      )?_c('span',{staticClass:"discount-percent"},[_vm._v(_vm._s((
          ((_vm.props.discount.maximum_price.final_price.value -
            _vm.props.discount.maximum_price.regular_price.value) /
            _vm.props.discount.maximum_price.regular_price.value) *
          100
        ).toFixed(0) + "%"))]):_vm._e()]}):_vm._e(),_vm._v(" "),_vm._t("old",function(){return [_c('del',{staticClass:"sf-price__old",class:{ 'display-none': !_vm.props.special }},[_vm._v("\n      "+_vm._s(_vm.props.regular)+"\n    ")])]},null,{ props: _vm.props }),_vm._v(" "),_vm._t("special",function(){return [_c('ins',{staticClass:"sf-price__special",class:{ 'display-none': !_vm.props.special }},[_vm._v("\n      "+_vm._s(_vm.props.special)+"\n    ")])]},null,{ props: _vm.props })],2)}
var staticRenderFns = []

export { render, staticRenderFns }