














import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import { defineComponent, ref, useFetch } from "@nuxtjs/composition-api";
export default defineComponent({
  name: "Preload",
  props: {
    height: {
      type: Number,
      default: 0,
    },
  },
  components: {
    SkeletonLoader,
  },
  setup(props) {
    const heights = ref(0);
    heights.value = props.height;

    const numbers = ref([]);

    const generateNumbers = () => {
      for (let i = props.height; i >= 0; i -= 36) {
        numbers.value.push(i);
      }
    };
    useFetch(() => {
      generateNumbers();
    });

    return {
      numbers,
      heights
    };

  },
  methods: {
    randomInt: function () {
      return Math.floor(Math.random() * (100 - 60 + 1)) + 60;
    },
  },
});
