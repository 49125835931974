var render, staticRenderFns
import script from "./SliderArrow.vue?vue&type=script&lang=js&"
export * from "./SliderArrow.vue?vue&type=script&lang=js&"
import style0 from "./SliderArrow.vue?vue&type=style&index=0&id=1742a533&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1742a533",
  null
  
)

export default component.exports