import { computed, Ref, useContext } from "@nuxtjs/composition-api";
import type { Route } from "vue-router";
import type { ImageModifiers } from "@nuxt/image";
import { useImage } from "~/composables";
import { useUser } from "~/modules/customer/composables/useUser";
import { useWishlist } from "~/modules/wishlist/composables/useWishlist";
import { useProduct } from "~/modules/catalog/product/composables/useProduct";
import {
  getName,
  getPrice,
  getProductThumbnailImage,
  getShortDescription,
  getProductSmallImage,
} from "~/modules/catalog/product/getters/productGetters";
import { getAverageRating, getTotalReviews } from "~/modules/review/getters/reviewGetters";
import { useAddToCart } from "~/helpers/cart/addToCart";
import type { Product } from "~/modules/catalog/product/types";
import { getConfigurableProductPriceCommand } from "~/modules/catalog/pricing/getConfigurableProductPriceCommand";
import { getConfigurableProductSpecialPriceCommand } from "~/modules/catalog/pricing/getConfigurableProductSpecialPriceCommand";

export interface ProductCommonCardProps {
  title: string;
  short_description: string;
  link: Route;
  style: Record<string, string | number> | string;
  isAddedToCart: boolean;

  image: string;
  image_webp: string;
  imageTag: string;
  nuxtImgConfig: { [key in keyof ImageModifiers]?: ImageModifiers[key] };

  isInWishlist: boolean;
  isInWishlistIcon: string;
  wishlistIcon: string;

  regularPrice: string;
  specialPrice: string;
  maximumPrice: string;

  reviewsCount: number;
  scoreRating: number;
}

export type ProductWithCommonProductCardProps = Product & { commonProps: ProductCommonCardProps };

export const useProductsWithCommonProductCardProps = (products: Ref<Product[]>) => {
  const { getMagentoImage } = useImage();
  const { isInWishlist } = useWishlist();
  const { isAuthenticated } = useUser();
  const { isInCart } = useAddToCart();
  const { getProductPath } = useProduct();
  const context = useContext();

  /**
   * Most props of SfProductCard and SfProductCardHorizontal are the same.
   * To avoid passing tens of props to both components two times,
   * instead the below object is passed to them using `v-bind="product.commonProps"`
   */
  const productsWithCommonProductCardProps = computed<ProductWithCommonProductCardProps[]>(() =>
    products.value.map((product, index) => {
      const imageProps = {
        image: getMagentoImage(getProductSmallImage(product)),
        imageTag: "nuxt-img",
        nuxtImgConfig: { fit: "cover" },
      };

      const wishlistProps = {
        isInWishlist: isInWishlist({ product }),
        isInWishlistIcon: isAuthenticated.value ? "heart_fill" : "",
        wishlistIcon: isAuthenticated.value ? "heart" : "",
      };

      const image_webp = product.thumbnail?.url_webp;

      const price = getPrice(product);

      const priceProps = {
        regularPrice: context.$fc(price.regular),
        specialPrice: price.special && context.$fc(getPrice(product).special),
        maximumPrice: price.maximum && context.$fc(getPrice(product).maximum),
      };

      const reviewProps = {
        reviewsCount: getTotalReviews(product),
        scoreRating: getAverageRating(product),
      };

      const short_description = getShortDescription(product);

      const product_custom_label = product.product_custom_label_data ? product.product_custom_label_data : "";
      const product_custom_label_data = product.product_custom_label_data ? product.product_custom_label_data : "";

      const product_image_label = product.product_image_label ? product.product_image_label : "";
      const product_frame = product.product_frame ? product.product_frame : "";
      const product_price_type_show = product.product_price_type_show ? product.product_price_type_show : "";
      const minimum_price = product.price_range.minimum_price.final_price.value ? product.price_range.minimum_price.final_price.value : "";
      const maximum_price = product.price_range.maximum_price.final_price.value ? product.price_range.maximum_price.final_price.value : "";
      const flash_sale_price = product.flash_sale_price ? JSON.parse(product.flash_sale_price) : null;
      const flash_sale_product_price = flash_sale_price ? Number(flash_sale_price.product_price) : 0;
      const flash_sale_product_sale_price = flash_sale_price ? Number(flash_sale_price.product_sale_price) : 0;
      const flash_sale_product_discount = flash_sale_price ? Number(flash_sale_price.product_discount) : 0;
      const flash_sale_from = flash_sale_price ? flash_sale_price.event_from : null;
      const flash_sale_to = flash_sale_price ? flash_sale_price.event_to : null;
      const flash_sale_qty = flash_sale_price ? Number(flash_sale_price.flash_sale_qty) : 0;
      const qty_ordered = flash_sale_price ? Number(flash_sale_price.qty_ordered) : 0;
      const is_currently_flash_sale = product.is_currently_flash_sale;
      

      const productPrice = getConfigurableProductPriceCommand(product);
      const productSpecialPrice = getConfigurableProductSpecialPriceCommand(product);

      const productPriceDiscount = Math.round(100 - (productSpecialPrice * 100) / productPrice);


      const link = context.localeRoute(getProductPath(product));
      const stock_status = product.stock_status;

      const commonProps = {
        title: getName(product),
        short_description,
        product_custom_label,
        product_custom_label_data,
        flash_sale_qty,
        product_image_label,
        product_frame,
        product_price_type_show,
        flash_sale_product_price,
        flash_sale_product_sale_price,
        flash_sale_product_discount,
        flash_sale_from,
        flash_sale_to,
        qty_ordered,
        is_currently_flash_sale,
        minimum_price,
        maximum_price,
        productPrice,
        productSpecialPrice,
        productPriceDiscount,
        stock_status,
        link,
        style: { "--index": index }, // used for transition animation
        isAddedToCart: isInCart(product),
        image_webp,
        ...imageProps,
        ...wishlistProps,
        ...priceProps,
        ...reviewProps,
      };
      
      return {
        ...product,
        commonProps,
      };
    })
  );
  return { productsWithCommonProductCardProps };
};
