



























































import axios from "axios";
import {
  defineComponent,
  PropType,
  ref,
  useFetch,
  onMounted,
} from "@nuxtjs/composition-api";
import { ImageModifiers } from "@nuxt/image";
import { SfButton } from "@storefront-ui/vue";
import { useConfig } from "~/composables";
import VueSlickCarousel from "~/components/VueSlickCarousel.vue";
import Preload from "~/components/Preload.vue";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import twoLeftOneRight from "./Banner/twoLeftOneRight.vue";
import oneLeftTwoRight from "./Banner/oneLeftTwoRight.vue";
import VLazyImage from "v-lazy-image/v2";
import SfImage from "~/modules/catalog/product/components/SfImage.vue";

export default defineComponent({
  name: "HeroSection",
  components: {
    VueSlickCarousel,
    SfButton,
    SfImage,
    Preload,
    twoLeftOneRight,
    oneLeftTwoRight,
    VLazyImage,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    imageHeight: {
      type: [Number, String],
      default: "",
    },
    imageSrc: {
      type: String,
      default: "",
    },
    imageTag: {
      type: String,
      default: "",
    },
    imageWidth: {
      type: [Number, String],
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    nuxtImgConfig: {
      type: Object as PropType<ImageModifiers | {}>,
      default: () => ({}),
    },
    mini_banner_id: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const { config } = useConfig();
    const slides = ref();
    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;
    const isLoaded = ref(false);
    const templateSlide = ref(null);

    const slidesToShowConfig = () => {
      if (templateSlide.value == "default") {
        return 1;
      } else if (templateSlide.value.indexOf("3columns") > -1) {
        return 3;
      } else if (templateSlide.value.indexOf("4columns") > -1) {
        return 4;
      } else if (templateSlide.value.indexOf("2columns") > -1) {
        return 2;
      } else if (
        templateSlide.value == "2left1right" ||
        templateSlide.value == "1left2right"
      ) {
        return 1;
      }
    };

    const rowsConfig = () => {
      if (templateSlide.value.indexOf("2rows") > -1) {
        return 2;
      } else {
        return 1;
      }
    };

    const queryMiniBanner = async () => {
      if (props.mini_banner_id) {
        const response = await axios.get(
          baseUrl + "rest/V1/utech/slider/" + props.mini_banner_id,
          {
            headers: {
              Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
            },
          }
        );
        templateSlide.value = response.data[0];
        slides.value = response.data[1];
        isLoaded.value = true;
      }
    };

    const { fetch, fetchState } = useFetch(() => {
      queryMiniBanner();
    });
    fetch();

    onMounted(async () => {
      // if (props.mini_banner_id) {
      //   const response = await axios.get(
      //     baseUrl + "rest/V1/utech/slider/" + props.mini_banner_id,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
      //       },
      //     }
      //   );
      //   templateSlide.value = response.data[0];
      //   slides.value = response.data[1];
      //   isLoaded.value = true;
      // }
    });
    // fetch();

    return {
      slides,
      baseMediaUrl,
      isLoaded,
      slidesToShowConfig,
      rowsConfig,
      templateSlide,
    };
  },
  data() {
    return {
      responsive: [
        {
          breakpoint: 1023,
          settings: "unslick",
          // breakpoint: 767,
          // settings: {
          //   slidesToShow: 1,
          //   slidesToScroll: 1,
          //   dots: false,
          // },
        },
      ],
    };
  },
});
