














































































import axios from "axios";
import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useFetch,
} from "@nuxtjs/composition-api";
import LazyHydrate from "vue-lazy-hydration";
import { useCache, CacheTagPrefix } from "@vue-storefront/cache";
import { SfBanner, SfBannerGrid } from "@storefront-ui/vue";
import HeroSection from "~/components/HeroSection.vue";
import MiniBanner from "~/components/MiniBanner.vue";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import { useConfig } from "~/composables";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import CustomBlocks from "~/components/CustomBlocks.vue";
import ContentBlocks from "~/components/ContentBlocks.vue";
import Blog from "~/components/Blog/Tabs.vue";
import Preload from "~/components/Preload.vue";
import useApi from "~/composables/useApi";
import { CategoryTree } from "~/modules/GraphQL/types";
import CategoryProducts from "./CategoryProducts.gql";
import FlashSaleHomepage from "~/components/FlashSales/Homepage.vue";
import type { CmsPage } from '~/modules/GraphQL/types';
import { useContent } from "~/composables";
export default defineComponent({
  name: "HomePage",
  components: {
    Preload,
    Blog,
    HeroSection,
    MiniBanner,
    LazyHydrate,
    LoadWhenVisible,
    SfBanner,
    SfBannerGrid,
    ContentBlocks,
    CustomBlocks,
    ContactUsForm: () =>
      import(/* webpackPrefetch: true */ "~/components/ContactUsForm.vue"),
    AboutUsHomePageSection: () =>
      import(/* webpackPrefetch: true */ "~/components/AboutUsHomePageSection.vue"),
    AllBrandHomePage: () =>
      import(/* webpackPrefetch: true */ "~/components/AllBrandHomePage.vue"),
    CouponHomePage: () =>
      import(/* webpackPrefetch: true */ "~/components/Coupon/CouponHomePage.vue"),
    InstagramFeed: () =>
      import(/* webpackPrefetch: true */ "~/components/InstagramFeed.vue"),
    MobileStoreBanner: () =>
      import(/* webpackPrefetch: true */ "~/components/MobileStoreBanner.vue"),
    NewProducts: () =>
      import(/* webpackPrefetch: true */ "~/components/NewProducts.vue"),
    CategoryProducts: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryProducts.vue"),
    TabProduct: () =>
      import(/* webpackPrefetch: true */ "~/components/TabProduct.vue"),
    HighlightProduct: () =>
      import(/* webpackPrefetch: true */ "~/components/HighlightProduct.vue"),
    FlashSaleHomepage,
    TextCategoryHighlight: () =>
      import(/* webpackPrefetch: true */ "~/components/TextCategoryHighlight.vue"),
    CategoryList: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryList.vue"),
    CategoryListIcon: () =>
      import(/* webpackPrefetch: true */ "~/components/CategoryListIcon.vue"),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { app } = useContext();
    const year = new Date().getFullYear();
    const { isDesktop } = app.$device;
    const { config } = useConfig();
    const { query } = useApi();

    const baseMediaUrl = config.value.secure_base_media_url;
    const baseUrl = config.value.secure_base_url;

    const categoryData = ref([]);
    const isLoaded = ref(false);
    const bannerSlideId = ref();
    const homeCategoryId = ref();
    const miniBannerId = ref();
    const coupon_enabled = ref();
    const i = ref(0);

    const logo = config.value.header_logo_src;
    const logo_web = baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';


    // const page = ref<CmsPage | null>(null);
    // const { loadPage } = useContent();

    const { fetch, fetchState } = useFetch(async () => {
      const headers = {
        Authorization: `Bearer ${process.env.MG_ACCESS_TOKEN}`,
      };

      const settings = await axios.get(baseUrl + "rest/V1/utech/settings", {
        headers,
      });
      coupon_enabled.value = settings?.data[0]?.coupon_enabled_homepage;
      bannerSlideId.value = settings?.data[0]?.homepage_banner;
      miniBannerId.value = settings?.data[0]?.mini_banner;
      homeCategoryId.value = settings?.data[0]?.home_category_id;
      const categoryIds = ref([]);
      Object.keys(homeCategoryId.value).forEach(async (key, index) => {
        const v = homeCategoryId.value[key];
        categoryIds.value.push(Number(v.id));
      });
      const { data } = await query<{ categoryList: [CategoryTree] }>(CategoryProducts, {
        filters: {
          ids: { in: categoryIds.value }
        },
        pageSize: 8
      });
      if (typeof data.categoryList != 'undefined' && data.categoryList) {
        data.categoryList.map((value, index) => {
          categoryData.value[index] = {
            id: value.id,
            name: value.name,
            url_key: value.url_key,
            url_path: value.url_path,
            path: value.path,
            image: value.image,
            top_content: value.top_content,
            bottom_content: value.bottom_content,
            products: value.products.items,
          };
          i.value++;
          if (i.value === Object.keys(homeCategoryId.value).length) {
            isLoaded.value = true;
          }
        });
      }
      // const highlightProduct = ref();
      // const { data } = await query<{ categoryList: [CategoryTree] }>(CategoryProducts, { filters: { url_key: { eq: 'highlight-product' } } });
      // console.log(data, 'highlightData');
      const tags = [{ prefix: CacheTagPrefix.View, value: "home" }];
      addTags([...tags]);
    });
    fetch();

    // useFetch(async () => {
    //   page.value = await loadPage({ identifier: "home-v2" });
    // });

    onMounted(async () => {
      document.body.setAttribute("id", "html-body");
      document.body.setAttribute("class", "home-page");
      // addTags([{ prefix: CacheTagPrefix.View, value: "home" }]);
    });

    // @ts-ignore
    return {
      baseMediaUrl,
      categoryData,
      isLoaded,
      bannerSlideId,
      miniBannerId,
      coupon_enabled,
      logo_web
    };
  },
  head() {
    return getMetaInfo({
      image: {
        url: this.logo_web
      }
    });
  },
});
