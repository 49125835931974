

































import {
  SfCarousel,
  SfSection,
  SfLoader,
  SfButton,
} from '@storefront-ui/vue';

import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useAddToCart } from '~/helpers/cart/addToCart';
import { useImage, useProduct } from '~/composables';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import type { Product } from '~/modules/catalog/product/types';
import SfProductCard from '../../category/components/views/SFProductCard.vue'
import { useProductsWithCommonProductCardProps } from '../../category/components/views/useProductsWithCommonCardProps';

export default defineComponent({
  name: 'ProductsCarousel',
  components: {
    SfCarousel,
    SfProductCard,
    SfSection,
    SfLoader,
    SfButton,
    SvgImage,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    products: {
      type: Array as PropType<Product[]>,
      required: false,
      default: () => [],
    },
    loading: Boolean,
    freeGift: {
      type: Boolean,
      required: false,
      default: false,
    },
    showAddToCartButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    promo: {
      type: Object,
      default: null,
    },
    showColorSwatch: {
      type: Boolean,
      default: false,
    },
    isFlashSale: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    const { isAuthenticated } = useUser();
    const { isInWishlist, addOrRemoveItem } = useWishlist();
    const { addItemToCart, isInCart } = useAddToCart();
    const { getProductPath } = useProduct();
    const { products } = toRefs(props);
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);

    const mappedProducts = computed(() => props.products.map((product) => ({
      // @ts-ignore
      ...product,
      isInWishlist: isInWishlist({ product }),
    })));

    const addItemToWishlist = async (product) => {
      await addOrRemoveItem({ product });
    };

    const { getMagentoImage, imageSizes } = useImage();
    const showColor = props.showColorSwatch;
    const flashSale = props.isFlashSale;

    //console.log(mappedProducts,'mappedProducts');

    return {
      addItemToCart,
      addItemToWishlist,
      isAuthenticated,
      isInCart,
      isInWishlist,
      mappedProducts,
      productGetters,
      getMagentoImage,
      imageSizes,
      getProductPath,
      productsWithCommonProductCardProps,
      showColor,
      flashSale
    };
  },
});
